import { useEffect, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import _ from 'lodash'
import { getVersionInfoFromLocalStorage } from 'client/util/versionInfoLocalStorage'
import IVersionInfo from 'shared/VersionInfo'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import { setSelectedVersionInfo } from 'client/redux/actions/version'
import api from 'client/util/api'
import Catalog from 'client/screens/Catalog/Catalog'
import AppEditor from 'client/screens/AppEditor/AppEditor'
import Reports from 'client/screens/Reports/Reports'
import Admin from 'client/screens/Admin/Admin'
import Confirmation from 'client/components/Confirmation/Confirmation'
import { isUserAdmin } from 'client/redux/selectors/auth'
import BloombergConnectsLogo from 'assets/svg/Logo.svg'
import GoogleAttributionLogo from 'assets/svg/google_attribution.svg'
import MuseumVersionType from 'shared/MuseumVersionType'
import Tools from 'client/screens/Tools/Tools'
import Internal from 'client/screens/Internal/Internal'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import Toast from 'client/components/Toast/Toast'
import { getCurrentLanguageHasGoogleTranslateEntries, t } from 'client/i18n'
import styled from 'styled-components'
import LoadingOverlayWithDialog from 'client/components/LoadingOverlay/LoadingOverlayWithDialog'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { useHelpScoutBeacon } from 'client/hooks/useHelpScoutBeacon'
import store from 'client/redux/store'
import { GQLMuseum } from 'shared/graphql/types/graphql'
import AppHeader from './Header'
import {
  AppContainer,
  DividerLine,
  DividerWrapper,
  LeftNavBody,
  LeftNavContainer,
  LeftNavFooter,
  LogosContainer,
  NavSubHeading,
  ContentSectionLabel,
  StyledNavLink
} from './styledComponents'
import BulkUploadNavLink from './BulkUploadNavLink'
import LeftNavContextualHelp from './LeftNavContextualHelp'
import TourNavLink from './TourNavLink'
import NewLinkTag from './NewLinkTag'

const useVersionInfo = () => {
  const dispatch = useDispatch()

  const versionInfo = useSelector(getSelectedVersionInfo)

  const fetchBestMatchForVersionInfo = useCallback(async () => {
    const localStorageVersionInfo = getVersionInfoFromLocalStorage()
    const queryString = localStorageVersionInfo
      ? `?${new URLSearchParams(localStorageVersionInfo as any).toString()}`
      : ''

    const { data } = await api.get<IVersionInfo>(`/api/versions/best-match${queryString}`)
    dispatch(setSelectedVersionInfo(data, false))
  }, [dispatch])

  useEffect(() => {
    fetchBestMatchForVersionInfo().catch((e) => {
      // eslint-disable-next-line no-console
      console.log(e)
    })
  }, [fetchBestMatchForVersionInfo])

  return versionInfo
}

const Divider = () => (
  <DividerWrapper>
    <DividerLine />
  </DividerWrapper>
)

const GoogleAttribution = styled.div`
  padding: 0 var(--spacing-medium);
  text-align: center;
`

const Authenticated = () => {
  const versionInfo = useVersionInfo()
  const [isCreatingDraft, setIsCreatingDraft] = useState<boolean | null>(null)
  const isAdmin = useSelector(isUserAdmin)
  const featureFlags = useFeatureFlags()
  const [errorDialog, setError] = useErrorDialog()
  const hasGoogleTranslateEntries = useMemo(() => getCurrentLanguageHasGoogleTranslateEntries(), [])

  const startCreatingDraftStatusPoller = useCallback(() => {
    let timeoutId: NodeJS.Timeout | null = null
    // when there's a draft running, we will set this to true,
    // the setSelectedVersionInfo & refresh will only occur when draft creation is finished
    let shouldRefresh = false

    const pollStatus = async () => {
      if (!versionInfo || (isCreatingDraft !== null && isCreatingDraft === false)) {
        return
      }
      try {
        const { data: creationStatus } = await api.get<{ isCreatingDraft: boolean }>(
          '/preview/creationStatus',
          {
            validateStatus: null
          }
        )
        setIsCreatingDraft(creationStatus.isCreatingDraft)

        if (creationStatus.isCreatingDraft) {
          shouldRefresh = true
          timeoutId = setTimeout(pollStatus, 1000)
        } else if (shouldRefresh) {
          // When reaching this point, it means there was a draft getting created before, and it's finished now.
          // In this case, we make an exlicit query to get the new draft version info
          const { data: museum } = await api.get<GQLMuseum>('/preview', { validateStatus: null })
          store.dispatch(
            setSelectedVersionInfo(
              {
                guideId: versionInfo.guideId,
                id: museum.id,
                versionType: museum.versionType as MuseumVersionType
              },
              true
            )
          )
        }
      } catch (error) {
        setError({
          error,
          title: t('Unable to create new draft')
        })
      }
    }
    pollStatus()

    return (): void => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isCreatingDraft, setError, versionInfo])

  useEffect(startCreatingDraftStatusPoller, [startCreatingDraftStatusPoller])
  useHelpScoutBeacon()

  if (!versionInfo || !featureFlags) {
    return null
  }

  const internalLinks = _.compact([
    featureFlags.DEVELOPER_MODE && (
      <StyledNavLink to="/internal/developer" key="developerLink">
        {t('Developer')}
      </StyledNavLink>
    ),
    featureFlags.ANALYTICS && (
      <StyledNavLink to="/internal/guide-starts" key="guide-start-nav-item">
        {t('Guide Starts')}
      </StyledNavLink>
    ),
    featureFlags.ANALYTICS && (
      <StyledNavLink to="/internal/first-opens" key="first-opens-nav-item">
        {t('First Opens')}
      </StyledNavLink>
    ),
    featureFlags.MARKETING && (
      <StyledNavLink to="/internal/asset-counts" key="asset-counts-nav-item">
        {t('Asset Counts')}
      </StyledNavLink>
    ),
    featureFlags.MARKETING && (
      <StyledNavLink to="/internal/generate-qr-codes" key="qr-codes-nav-item">
        {t('QR Code Generator')}
      </StyledNavLink>
    ),
    featureFlags.MANAGE_FEATURED_GUIDES && (
      <StyledNavLink to="/internal/explore" key="new-and-featured-nav-item">
        {t('New and Featured')}
      </StyledNavLink>
    )
  ])

  return (
    <AppContainer>
      <AppHeader />
      <LeftNavContainer>
        <LeftNavBody>
          <NavSubHeading>
            <ContentSectionLabel>
              {t('CONTENT')}
              <LeftNavContextualHelp />
            </ContentSectionLabel>
          </NavSubHeading>
          <StyledNavLink to="/catalog/exhibits">{t('Exhibitions')}</StyledNavLink>
          <TourNavLink />
          <StyledNavLink to="/catalog/items">{t('Items')}</StyledNavLink>
          <StyledNavLink to="/catalog/botanicalItems">{t('Botanical Items')}</StyledNavLink>
          <StyledNavLink to="/catalog/events">{t('Events')}</StyledNavLink>
          <StyledNavLink to="/catalog/images">{t('Images')}</StyledNavLink>
          <StyledNavLink to="/catalog/audios">{t('Audios')}</StyledNavLink>
          <StyledNavLink to="/catalog/videos">{t('Videos')}</StyledNavLink>
          <StyledNavLink to="/catalog/creators">{t('Creators')}</StyledNavLink>
          <Divider />
          <NavSubHeading>{t('GUIDE')}</NavSubHeading>
          <StyledNavLink to="/app-editor/about">{t('About')}</StyledNavLink>
          <StyledNavLink to="/app-editor/brand-assets">{t('Brand Assets')}</StyledNavLink>
          <StyledNavLink to="/app-editor/home">{t('Home')}</StyledNavLink>
          <StyledNavLink to="/app-editor/maps">{t('Map')}</StyledNavLink>
          <Divider />
          <NavSubHeading>{t('REPORTS')}</NavSubHeading>

          <StyledNavLink to="/reports/data-dashboard">
            {t('Data Dashboard')}
            <NewLinkTag />
          </StyledNavLink>

          {featureFlags.USAGE_REPORTS && (
            <StyledNavLink to="/reports/usage-reports">{t('Usage')}</StyledNavLink>
          )}
          {featureFlags.LOANER_REPORT && (
            <StyledNavLink to="/reports/loaner-device-usage-reports">
              {t('Loaner Device Usage')}
            </StyledNavLink>
          )}

          <Divider />
          <NavSubHeading>{t('TOOLS')}</NavSubHeading>
          {_.compact([
            featureFlags.BULK_UPLOAD && versionInfo.versionType === MuseumVersionType.PREVIEW && (
              <BulkUploadNavLink key="bulkUploadLink" />
            ),
            <StyledNavLink to="/tools/guide-export" key="guide-export-nav-item">
              {t('Guide Export')}
            </StyledNavLink>,
            <StyledNavLink
              to="/tools/accessibility-suggestions"
              key="accessibility-suggestions-nav-item"
            >
              {t('Accessibility Suggestions')}
            </StyledNavLink>
          ])}

          {!_.isEmpty(internalLinks) && (
            <>
              <Divider />
              <NavSubHeading>{t('INTERNAL')}</NavSubHeading>
              {internalLinks}
            </>
          )}

          {isAdmin && (
            <>
              <Divider />
              <NavSubHeading>{t('ADMIN')}</NavSubHeading>
              <StyledNavLink to="/admin/users">{t('Users')}</StyledNavLink>
              <StyledNavLink to="/admin/guides">{t('Guides')}</StyledNavLink>
            </>
          )}
          <LeftNavFooter>
            <LogosContainer>
              <div>
                <BloombergConnectsLogo style={{ width: '168px' }} />
              </div>
              {hasGoogleTranslateEntries && (
                <>
                  <GoogleAttribution>{t('Provided in part by Google Translate')}</GoogleAttribution>
                  <div>
                    <GoogleAttributionLogo style={{ width: '122px', height: '16px' }} />
                  </div>
                </>
              )}
            </LogosContainer>
          </LeftNavFooter>
        </LeftNavBody>
        <Confirmation />
        <Routes>
          <Route path="catalog/*" element={<Catalog />} />
          <Route path="app-editor/*" element={<AppEditor />} />
          <Route path="reports/*" element={<Reports />} />
          <Route path="admin/*" element={<Admin />} />
          <Route path="tools/*" element={<Tools versionInfo={versionInfo} />} />
          <Route path="internal/*" element={<Internal />} />
          <Route path="/*" element={<Navigate replace={true} to="catalog/exhibits" />} />
        </Routes>
      </LeftNavContainer>
      <Toast />
      {isCreatingDraft && <LoadingOverlayWithDialog loadingText={t('Creating draft...')} />}
      {errorDialog}
    </AppContainer>
  )
}

export default Authenticated
